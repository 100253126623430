var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.hiddenVisible,"close-on-click-modal":false,"width":"570px"},on:{"update:visible":function($event){_vm.hiddenVisible=$event},"close":_vm.dialogClose}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableHiddenLoading),expression:"tableHiddenLoading"}],staticClass:"sortabelClass",attrs:{"data":_vm.tableHiddenData,"border":"","border":"","row-key":"id"}},[_c('el-table-column',{attrs:{"prop":"name","label":_vm.title,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),(_vm.title === '隐患类别')?_c('el-table-column',{attrs:{"prop":"typeName","label":"所属隐患类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择所属隐患类型"},model:{value:(row.typeId),callback:function ($$v) {_vm.$set(row, "typeId", $$v)},expression:"row.typeId"}},_vm._l((_vm.hiddenTypeList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)]}}],null,false,3764391895)}):_vm._e(),(_vm.title === '使用细目')?_c('el-table-column',{attrs:{"label":"所属费用大类","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择所属费用大类"},model:{value:(row.typeId),callback:function ($$v) {_vm.$set(row, "typeId", $$v)},expression:"row.typeId"}},_vm._l((_vm.detailList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)]}}],null,false,1067404506)}):_vm._e(),_c('el-table-column',{attrs:{"label":"操作","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('span',{staticClass:"view-details",staticStyle:{"color":"#F56C6C"},on:{"click":function($event){return _vm.deleteConfig(row.id, $index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"footer-btn-grounp",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"add-btn",on:{"click":_vm.handelConfigHidden}},[_vm._v("添加配置项")]),_c('div',[_c('el-button',{on:{"click":_vm.handelClose}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.handleDangerConfig}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }