import request from '@/utils/request.js';

/**
 *系统配置项列表
 * @returns { Promise }
 */
export function configurationList(data) {
  return request({
    url: '/traffic-construction/configuration/list',
    method: 'post',
    data
  });
}

/**
 *配置项的具体配置列表
 * @returns { Promise }
 */
export function getConfigurationDetailSortList(data) {
  return request({
    url: '/traffic-construction/configurationDetail/sortList',
    method: 'post',
    data
  });
}

/**
 *添加/修改配置项的具体配置
 * @returns { Promise }
 */
export function addConfigurationDetailSortList(data) {
  return request({
    url: '/traffic-construction/configurationDetail/addOrEdit',
    method: 'post',
    data
  });
}

/**
 *删除配置项的具体配置
 * @returns { Promise }
 */
export function deleteConfigurationDetail(data) {
  return request({
    url: '/traffic-construction/configurationDetail/delete',
    method: 'post',
    data
  });
}

/**
 *隐患类型下拉列表（安全)
 * @returns { Promise }
 */
export function securitySortDropDownListConfigurationDetailApi(data) {
  return request({
    url: '/traffic-construction/configurationDetail/sortDropDownList',
    method: 'post',
    data
  });
}

/**
 *隐患类型下拉列表（职业健康)
 * @returns { Promise }
 */
export function healthSortDropDownListConfigurationDetailApi(data) {
  return request({
    url: '/traffic-construction/configurationDetail/hiddenTypeList',
    method: 'post',
    data
  });
}

/**
 *隐患类型下拉列表（环保)
 * @returns { Promise }
 */
export function envSortDropDownListConfigurationDetailApi(data) {
  return request({
    url: '/traffic-construction/configurationDetail/environmentHiddenTypeList',
    method: 'post',
    data
  });
}

/**
 *安全经费使用细目下拉列表
 * @returns { Promise }
 */
export function getCostCategoriesDropDownListApi(data) {
  return request({
    url: '/traffic-construction/configurationDetail/costCategoriesDropDownList',
    method: 'post',
    data
  });
}
