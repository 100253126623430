<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="hiddenVisible"
    :close-on-click-modal="false"
    @close="dialogClose"
    width="570px"
  >
    <el-table :data="tableHiddenData" border v-loading="tableHiddenLoading" border row-key="id" class="sortabelClass">
      <el-table-column prop="name" :label="title" align="center">
        <template slot-scope="{ row }">
          <el-input v-model="row.name" placeholder="点击输入"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="所属隐患类型" align="center" v-if="title === '隐患类别'">
        <template slot-scope="{ row }">
          <el-select v-model="row.typeId" placeholder="请选择所属隐患类型">
            <el-option v-for="item in hiddenTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="所属费用大类" align="center" v-if="title === '使用细目'">
        <template slot-scope="{ row }">
          <el-select v-model="row.typeId" placeholder="请选择所属费用大类">
            <el-option v-for="item in detailList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="{ row, $index }">
          <span class="view-details" @click="deleteConfig(row.id, $index)" style="color:#F56C6C">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="footer-btn-grounp">
      <div class="add-btn" @click="handelConfigHidden">添加配置项</div>
      <div>
        <el-button @click="handelClose">取消</el-button>
        <el-button type="primary" @click="handleDangerConfig" :loading="btnLoading">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs';
import { configEditSort } from 'api/common';
import {
  addConfigurationDetailSortList,
  getConfigurationDetailSortList,
  deleteConfigurationDetail,
  securitySortDropDownListConfigurationDetailApi,
  healthSortDropDownListConfigurationDetailApi,
  getCostCategoriesDropDownListApi,
  envSortDropDownListConfigurationDetailApi
} from 'api/super-admin/common';

import { getItem } from '@/utils/storage.js';
export default {
  name: 'ConfigHiddenDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      title: '',
      btnLoading: false,
      hiddenVisible: this.dialogVisible,
      tableHiddenLoading: false,
      tableHiddenData: [],
      hiddenTypeList: [], //隐患类型下拉列表
      detailList: [], //使用细目下拉列表
      configId: '' //所属配置项id
    };
  },
  methods: {
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.sortabelClass .el-table__body-wrapper tbody');
      const _this = this;
      let pamers = {};
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          if (newIndex - oldIndex > 1) {
            const currRow = _this.tableHiddenData.splice(oldIndex, 1)[0];
            pamers = currRow;
            _this.tableHiddenData.splice(newIndex, 0, currRow);
            pamers.newSort = _this.tableHiddenData[newIndex - 1].sort;
          } else if (oldIndex - newIndex > 1) {
            const currRow = _this.tableHiddenData.splice(oldIndex, 1)[0];
            pamers = currRow;
            _this.tableHiddenData.splice(newIndex, 0, currRow);
            pamers.newSort = _this.tableHiddenData[newIndex + 1].sort;
          } else {
            const currRow = _this.tableHiddenData.splice(oldIndex, 1)[0];
            pamers = currRow;
            _this.tableHiddenData.splice(newIndex, 0, currRow);
            pamers.newSort = _this.tableHiddenData[oldIndex].sort;
          }
          configEditSort(pamers).then(res => {
            if (res.code === 200) {
              _this.$message.success('操作成功');
              _this.getConfigurationDetailSortList();
            }
          });
        }
      });
    },
    initTabelConfigFunc(id, title, module) {
      //父祖传访问子组件传入的值
      this.configId = id;
      this.title = title;
      this.getConfigurationDetailSortList();
      if (this.title === '隐患类别' && module === 2) {
        //这里调取安全的下拉api
        this.securitySortDropDownListConfigurationDetail();
      } else if (this.title === '使用细目') {
        this.getCostCategoriesDropDownList();
      } else if (this.title === '隐患类别' && module === 6) {
        this.healthSortDropDownListConfigurationDetail();
      } else {
        this.envSortDropDownListConfigurationDetail();
      }
    },
    handelConfigHidden() {
      //添加项
      let obj = {
        name: '',
        configId: this.configId,
        tenantId: getItem('orgId'),
        typeId: ''
      };
      this.tableHiddenData.push(obj);
    },
    deleteConfig(id, index) {
      //删除
      this.$confirm('确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          this.deleteConfigurationDetail(id);
        } else {
          this.tableHiddenData.splice(index, 1);
        }
      });
    },
    handleDangerConfig() {
      //确定
      if (!this.fillValidateFunc()) {
        return false;
      }
      this.addConfigurationDetailSortList();
    },
    fillValidateFunc() {
      //判断每行数据是否都输入，返回Boolean值
      let flag = true;
      this.tableHiddenData.find(item => {
        if (!item.name) {
          this.$message.warning('请填写完整');
          flag = false;
        }
        if (this.title === '隐患类别' && !item.typeId) {
          this.$message.warning('请填写完整');
          flag = false;
        }
      });
      return flag;
    },
    handelClose() {
      this.$emit('update:dialogVisible', false);
    },
    dialogClose() {
      this.$emit('update:dialogVisible', false);
    },
    //添加/修改配置项的具体配置接口
    addConfigurationDetailSortList() {
      this.btnLoading = true;
      addConfigurationDetailSortList({ configurationDetailParamList: this.tableHiddenData })
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$emit('update:dialogVisible', false);
            this.$message.success('操作成功');
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //配置项的具体配置列表接口
    getConfigurationDetailSortList() {
      this.tableHiddenLoading = true;
      //配置项的具体配置列表
      getConfigurationDetailSortList({ tenantId: getItem('orgId'), configId: this.configId }).then(res => {
        if (res.code === 200) {
          this.tableHiddenLoading = false;
          this.tableHiddenData = res.data;
        }
      });
    },
    //删除接口
    deleteConfigurationDetail(id) {
      //配置项的具体配置列表
      deleteConfigurationDetail({ id }).then(res => {
        if (res.code === 200) {
          this.btnLoading = false;
          this.$message.success('删除成功');
          this.getConfigurationDetailSortList();
        }
      });
    },
    //隐患类型下拉列表(安全)
    securitySortDropDownListConfigurationDetail() {
      //配置项的具体配置列表
      securitySortDropDownListConfigurationDetailApi({ tenantId: getItem('orgId') }).then(res => {
        if (res.code === 200) {
          this.hiddenTypeList = res.data;
        }
      });
    },
    //隐患类型下拉列表(职业健康)
    healthSortDropDownListConfigurationDetail() {
      //配置项的具体配置列表
      healthSortDropDownListConfigurationDetailApi({ tenantId: getItem('orgId') }).then(res => {
        if (res.code === 200) {
          this.hiddenTypeList = res.data;
        }
      });
    },
    //隐患类型下拉列表(安全)
    envSortDropDownListConfigurationDetail() {
      //配置项的具体配置列表
      envSortDropDownListConfigurationDetailApi({ tenantId: getItem('orgId') }).then(res => {
        if (res.code === 200) {
          this.hiddenTypeList = res.data;
        }
      });
    },
    //安全经费使用细目下拉列表
    getCostCategoriesDropDownList() {
      //配置项的具体配置列表
      getCostCategoriesDropDownListApi({ tenantId: getItem('orgId') }).then(res => {
        if (res.code === 200) {
          this.detailList = res.data;
        }
      });
    }
  },

  watch: {
    dialogVisible(val) {
      this.hiddenVisible = val;
      this.timer = setTimeout(() => {
        this.rowDrop();
      }, 500);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  computed: {
    dialogTitle() {
      return this.title + '配置';
    }
  }
};
</script>

<style scoped>
.footer-btn-grounp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}
.add-btn {
  width: 94px;
  height: 32px;
  border: 1px solid #005aab;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #005aab;
  font-size: 14px;
  cursor: pointer;
}
</style>
